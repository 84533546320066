export var name = "g1";
export var version = "0.18.0";
export var description = "Gramex 1.x interaction library";
export var license = "MIT";
export var author = "S Anand <s.anand@gramener.com>";
export var module = "modules.js";
export var main = "dist/g1.js";
export var jsdelivr = "dist/g1.min.js";
export var unpkg = "dist/g1.min.js";
export var repository = {"type":"git","url":"git@code.gramener.com:s.anand/g1.git"};
export var scripts = {"lint":"eslint index*.js src && eclint check '**/*.html' '**/*.js' '**/*.css' '**/*.yaml' '**/*.md'","build":"rimraf dist && json2module package.json > src/package.js && rollup -c","dev":"rimraf dist && json2module package.json > src/package.js && rollup -c -w","pretest":"npm run lint && npm run build && browserify -s tape -r tape -o test/tape.js","server":"npm run pretest && npm run lint && node test/server.js","test":"tape test/test-*.js | faucet && node test/server.js puppeteer | tap-merge | faucet","test-chrome":"node test/server.js chrome | tap-merge | faucet","test-edge":"node test/server.js MicrosoftEdge | tap-merge | faucet","test-firefox":"node test/server.js firefox | tap-merge | faucet","prepublishOnly":"npm test"};
export var devDependencies = {"babel-core":"6","babel-plugin-external-helpers":"6","babel-plugin-transform-runtime":"6","babel-preset-env":"1","babel-preset-es2015":"6","babelrc-rollup":"3","bootstrap":"4.1","bootstrap-select":"1.13","browserify":"14","component-emitter":"1","d3":"4","d3-scale-chromatic":"1","deepmerge":"2","eclint":"2","es6-promise":"4","eslint":"4","express":"4","faucet":"0.0","font-awesome":"4","glob":"7.1","html-minifier":"3","is-plain-object":"2","jquery":"3","json2module":"0.0","leaflet":"1.3","lodash":"4","moment":"2","morphdom":"2","numeral":"2","popper.js":"1","puppeteer":"0.13","regenerator-runtime":"0.11","rimraf":"2","rollup":"1","rollup-plugin-babel":"3","rollup-plugin-commonjs":"10","rollup-plugin-node-resolve":"5","rollup-plugin-uglify":"6","rollup-pluginutils":"2","selenium-webdriver":"3","tap-merge":"0.3","tape":"4","topojson":"3","unfetch":"3"};
export var dependencies = {"d3-array":"2","lodash-es":"4"};
